<template>
    <div class="information">
        <div class="top">
            <div class="title" v-html="introduction"></div>

            <div class="info">
                <div class="text1">{{ $t('chan-pin-pin-pai') }}</div>
                <div class="text2">{{ getName(productInfo, ['brand', 'enBrand']) }}</div>
            </div>

            <div class="info" v-if="isChinese">
                <div class="text1">{{ $t('ying-wen-pin-ming') }}</div>
                <div class="text2">{{ productInfo.enName }}</div>
            </div>

            <div class="info">
                <div class="text1">{{ $t('chan-pin-lei-bie') }}</div>
                <div class="text2">{{ productCategoryName }}</div>
            </div>
            <div class="info" v-if="origin">
                <div class="text1">{{ $t('chan-di') }}</div>
                <div class="text2">{{ $t(origin) }}</div>
            </div>
            <div class="info" v-if="applicationFieldInfo">
                <div class="text1">{{ $t('ying-yong-ling-yu') }}</div>
                <div class="text2">
                    {{ applicationFieldInfo }}
                </div>
            </div>

            <div class="info" v-if="tags.length > 0">
                <div class="text1">{{ $t('chan-pin-biao-qian') }}</div>
                <div class="text2">
                    <el-tag v-for="(item, index) in tags" :key="index" effect="plain" size="mini">
                        {{ getName(item) }}
                    </el-tag>
                </div>
            </div>
        </div>

        <div class="detail" v-html="detailImg"></div>
    </div>
</template>
<script>
export default {
    name: 'information',
    props: ['productInfo', 'tags', 'applicationField', 'productCategory'],
    computed: {
        origin() {
            return (
                [...(this.productInfo.origin1 || [])]
                    .map(item => {
                        return this.getName(item);
                    })
                    .join('、') ||
                this.productInfo.origin ||
                ''
            );
        },
        productCategoryName() {
            if (this.productCategory) {
                return this.getName(this.productCategory);
            } else if (this.productInfo.productCategory) {
                return this.getName(this.productInfo.productCategory);
            } else {
                return '';
            }
        },
        applicationFieldInfo() {
            let list = this.productInfo.applicationField ? [...this.productInfo.applicationField] : [];
            if (this.applicationField) {
                list = this.applicationField;
            }
            list = list.map(item => {
                return this.getName(item);
            });
            if (this.productInfo.customApplicationField) {
                const _list = this.productInfo.customApplicationField.split(',');

                list = [...list, ..._list];
            }
            return list.join('、');
        },
        detailImg() {
            var detail = this.productInfo.detailImg || '';
            detail = detail.replace(/<img/g, '<img  oncontextmenu="return false;" ');
            detail = detail.replace(
                /<video/g,
                '<video oncontextmenu="return false;" onmouseleave="leaveVideo(this)" controlsList="nodownload noremote footbar" '
            );
            return detail;
        },
        introduction() {
            return this.getName(this.productInfo, ['introduction', 'enIntroduction']).replace(/\n/g, '<br/>');
        }
    }
};
</script>
<style lang="scss" scoped>
.top {
    padding: 50px;
    background-color: #fff;
}

.title {
    font-size: 14px;
    font-weight: bold;
    color: #292c33;
    line-height: 22px;
    margin-bottom: 30px;
}

.info {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .text1 {
        font-size: 13px;
        font-weight: bold;
        color: #444444;
        line-height: 24px;
        min-width: 52px;
        margin-right: 20px;
    }

    .text2 {
        font-size: 13px;
        color: #444444;
        line-height: 24px;

        .el-tag + .el-tag {
            margin-left: 10px;
        }

        .el-tag {
            border: 1px solid #cfd5e1;
            color: #292c33;
        }
    }
}

.detail {
    margin-top: 30px;
    background-color: #fff;
    padding: 50px;
}

.information {
    padding-bottom: 100px;
}
</style>

import { render, staticRenderFns } from "./ProductParameter.vue?vue&type=template&id=23742c46&scoped=true&"
import script from "./ProductParameter.vue?vue&type=script&lang=js&"
export * from "./ProductParameter.vue?vue&type=script&lang=js&"
import style0 from "./ProductParameter.vue?vue&type=style&index=0&id=23742c46&lang=scss&scoped=true&"
import style1 from "./ProductParameter.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23742c46",
  null
  
)

export default component.exports
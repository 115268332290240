<template>
    <div class="main">
        <el-table
            :show-header="false"
            :row-class-name="tableRowClassName"
            :cell-class-name="tableCellClassName"
            :span-method="objectSpanMethod"
            :data="tableData"
            style="width: 100%"
        >
            <el-table-column width="160" prop="name" :label="$t('zhu-lei-ming')" align="center"> </el-table-column>
            <el-table-column width="220" prop="childName" :label="$t('fu-lei-ming')" align="right"> </el-table-column>
            <el-table-column prop="value" :label="$t('zhi')"> </el-table-column>
        </el-table>
    </div>
</template>

<script>
export default {
    name: 'Parameter',
    data() {
        return {
            list: []
        };
    },
    props: {
        productId: {
            type: [Number, String],
            default: 0
        },
        productParameters: {
            type: Array,
            default: () => {
                return [];
            }
        }
    },
    computed: {
        tableData() {
            var list = [...this.list];
            list = list.map(item => {
                if (item.children.length > 0) {
                    return item.children.map((_child, _childIndex) => {
                        return {
                            name: this.getName(item),
                            childName: this.getName(_child),
                            value: _child.value,
                            rowspan: _childIndex == 0 ? item.children.length : 0
                        };
                    });
                } else {
                    return {
                        name: this.getName(item),
                        childName: '-',
                        value: item.value,
                        rowspan: 1
                    };
                }
            });

            return list.flat();
        }
    },
    watch: {
        productId() {
            this.getInfo();
        },
        productParameters() {
            this.list = this.productParameters;
        }
    },
    mounted() {
        this.getInfo();
    },
    methods: {
        getInfo() {
            if (this.productId) {
                this.$http.post('/productParameter/getProductParameter?productId=' + this.productId).then(res => {
                    this.list = res;
                });
            } else {
                this.list = this.productParameters;
            }
        },
        objectSpanMethod({ row, column, rowIndex, columnIndex }) {
            if (columnIndex === 0) {
                if (row['rowspan']) {
                    return {
                        rowspan: row['rowspan'],
                        colspan: 1
                    };
                } else {
                    return { rowspan: 0, colspan: 1 };
                }
            } else {
                return {
                    rowspan: 1,
                    colspan: 1
                };
            }
        },
        tableRowClassName({ row, rowIndex }) {
            if (row['rowspan']) {
                return 'inlineRow';
            } else {
                return '';
            }
        },
        tableCellClassName({ row, column, rowIndex, columnIndex }) {
            if (columnIndex !== 0) {
                return 'content-td';
            }
            return '';
        }
    }
};
</script>
<style lang="scss" scoped>
.main {
    padding: 60px;
    background-color: #fff;
}
</style>
<style lang="scss">
.main {
    .el-table::before {
        background-color: #fff;
    }

    td {
        border-color: #f2f3f5;
        &.content-td {
            padding: 15px 30px;
            font-size: 12px;
            color: #292c33;
            line-height: 17px;
        }
    }
}
.inlineRow td {
    &:not(:first-child) {
        border-bottom-width: 0px;
    }
    &:first-child {
        border-right: 1px solid #f2f3f5;
        font-size: 12px;
        font-weight: bold;
        color: #292c33;
        line-height: 17px;
    }
}
</style>

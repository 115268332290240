<template>
    <div class="productImg">
        <div class="imgBox" onmousewheel="return false;" @mouseenter="enterEvent" @mousemove="moveEvent">
            <template v-if="chooseIndex !== 'video'">
                <auto-img :src="nowImg" mul="100%"></auto-img>
                <div class="zoomBox" :style="{ left: left + 'px', top: top + 'px' }"></div>
                <div class="zoomShow">
                    <div
                        class="zoomImg"
                        :style="{
                            left: 0 - left * 2 + 'px',
                            top: 0 - top * 2 + 'px'
                        }"
                    >
                        <auto-img :src="nowImg" mul="100%"></auto-img>
                    </div>
                </div>
            </template>
            <div class="videoBig" v-else>
                <video
                    :src="videoInfo.video"
                    controls="controls"
                    controlsList="nodownload noremote footbar"
                    style="height: 100%; max-width: 100%;"
                    :poster="videoInfo.img"
                    oncontextmenu="return false;"
                    onmouseleave="leaveVideo(this)"
                >
                    {{$t('nin-de-liu-lan-qi-bu-zhi-chi-video-biao-qian')}}
                </video>
            </div>
        </div>

        <div class="imgSwiper">
            <img @click="goPre" src="../../assets/icon-arrow-left.png" class="icon left" v-show="swiperLeft > 0" />
            <div class="imgMain">
                <div class="content" :style="swiperLeftVal">
                    <div
                        class="imgItem"
                        v-if="video"
                        :class="{ active: chooseIndex === 'video' }"
                        @click="chooseIndex = 'video'"
                    >
                        <div class="video">
                            <i class="el-icon-video-camera"></i>
                        </div>
                    </div>
                    <div
                        class="imgItem"
                        v-for="(item, index) in imgList"
                        :key="index"
                        :class="{ active: chooseIndex === index }"
                    >
                        <auto-img :src="item" mul="100%" @imgClick="chooseIndex = index"></auto-img>
                    </div>
                </div>
            </div>

            <img src="../../assets/icon-arrow-right.png" @click="goNext" class="icon right" v-show="swiperRight > 0" />
        </div>
    </div>
</template>

<script>
import imgZoom from 'vue2.0-zoom';
export default {
    name: 'productImg',
    props: {
        img: {
            type: String,
            default: ''
        },
        video: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            chooseIndex: 0,
            left: 0,
            top: 0,
            enterInfo: {
                x: 0,
                y: 0
            },
            show: false,
            swiperLeft: 0 //左边的数量
        };
    },
    watch: {
        video() {
            if (this.video) {
                this.chooseIndex = 'video';
            }
        }
    },
    created() {
        if (this.video) {
            this.chooseIndex = 'video';
        }
    },
    computed: {
        imgList() {
            return this.img.split(',');
        },
        nowImg() {
            return typeof this.chooseIndex === 'number' ? this.imgList[this.chooseIndex] : 'video';
        },
        swiperRight() {
            // 右边隐藏的图片的数量
            return this.imgList.length - this.swiperLeft - 5;
        },
        swiperLeftVal() {
            return {
                left: 0 - this.swiperLeft * 88 + 'px'
            };
        },
        mainImg() {
            return this.imgList.length > 0 ? this.imgList[0] : '';
        },
        videoInfo() {
            if (this.video) {
                const video = this.video.split(',');
                return {
                    img: video.length > 1 ? video[1] : '',
                    video: video[0]
                };
            } else {
                return {
                    img: '',
                    video: ''
                };
            }
        }
    },
    methods: {
        enterEvent(e) {
            if (this.chooseIndex === 'video') {
                return;
            }
            this.enterInfo = {
                x: e.clientX,
                y: e.clientY
            };
            this.setxy(e.offsetX - 75, e.offsetY - 75, true);
        },
        moveEvent(e) {
            if (this.chooseIndex === 'video') {
                return;
            }
            this.setxy(e.clientX - this.enterInfo.x, e.clientY - this.enterInfo.y);
            this.enterInfo = {
                x: e.clientX,
                y: e.clientY
            };
        },
        setxy(x, y, start) {
            let _left = this.left;
            if (start) {
                _left = x;
            } else {
                _left += x;
            }

            if (_left < 0) {
                _left = 0;
            } else if (_left >= 510 - 150) {
                _left = 510 - 150;
            }
            this.left = _left;

            let _top = this.top;
            if (start) {
                _top = y;
            } else {
                _top += y;
            }

            if (_top < 0) {
                _top = 0;
            } else if (_top >= 510 - 150) {
                _top = 510 - 150;
            }
            this.top = _top;
        },
        goPre() {
            if (this.swiperLeft > 5) {
                this.swiperLeft -= 5;
            } else if (this.swiperLeft >= 0) {
                this.swiperLeft = 0;
            }
        },
        goNext() {
            if (this.swiperRight > 5) {
                this.swiperLeft += 5;
            } else if (this.swiperRight >= 0) {
                this.swiperLeft = this.swiperRight;
            }
        }
    },
    components: {
        imgZoom
    }
};
</script>

<style lang="scss" scoped>
$-zoom: 2;
$-imgWdith: 510px;
$-boxWdith: 150px;

.videoBig {
    background-color: #000;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.productImg {
    position: relative;
    flex-shrink: 0;
}
.video {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(#ffa526, 0.2);
    height: 100%;

    flex-direction: column;
    overflow: hidden;

    i {
        font-size: 28px;
    }
    span {
        margin-top: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 0 10px;
        width: 100%;
        box-sizing: border-box;
    }
}
.imgBox {
    width: $-imgWdith;
    height: $-imgWdith;
    position: relative;
    // overflow: hidden;

    &:hover {
        .zoomBox {
            display: block;
        }
        .zoomShow {
            display: block;
        }
    }
}

.zoomBox {
    position: absolute;
    background-color: rgb(0, 0, 0, 0.4);
    width: $-boxWdith;
    height: $-boxWdith;
    top: 0;
    left: 0;
    display: none;
}

.zoomShow {
    position: absolute;
    top: 0;
    left: $-imgWdith;
    width: $-boxWdith * $-zoom;
    height: $-boxWdith * $-zoom;
    overflow: hidden;
    display: none;
}

.zoomImg {
    width: $-imgWdith * $-zoom;
    height: $-imgWdith * $-zoom;
    position: absolute;
}

.imgSwiper {
    padding: 0 35px;
    position: relative;
    width: 510px;
    box-sizing: border-box;
    .imgMain {
        width: 440px;
        overflow: hidden;
        position: relative;
        height: 112px;
        .content {
            display: flex;
            flex-wrap: nowrap;
            padding: 10px 0 14px;
            position: absolute;
            top: 0;
            left: 0px;
            transition: left ease-in-out 0.3s;
        }
        .imgItem {
            width: 76px;
            height: 76px;
            margin: 6px;
            flex-shrink: 0;
            cursor: pointer;

            &.active {
                // border: 1px solid #ffa526;
                box-shadow: 0 0 0 1px #ffa526;
            }
        }
    }

    .icon {
        width: 20px;
        height: 20px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        display: none;
        cursor: pointer;
        &.left {
            left: 2px;
        }

        &.right {
            right: 2px;
        }
    }

    &:hover {
        .icon {
            display: block;
        }
    }
}
</style>

<template>
    <div class="product-info">
        <div class="title fontYahei">{{ getName(productInfo) }}</div>

        <div class="sub">{{ getName(productInfo, ['model', 'enModel']) }}</div>
        <el-popover
            placement="right"
            trigger="click"
            v-if="productInfo.offlineExperience"
            v-model="showMap"
            width="640px"
        >
            <el-link type="warning" slot="reference">{{
                $t('ke-zai-imt-zhan-ting-xian-xia-ti-yan-dian-ji-cha-kan-di-zhi')
            }}</el-link>
            <Map v-if="showMap" />
            <div class="close-Map" @click="showMap = false">{{ $t('guan-bi') }}</div>
        </el-popover>

        <div class="product-info-item" v-if="productInfo.averageLeadTime">
            <div class="text1">{{ $t('ping-jun-jiao-huo-zhou-qi') }}</div>
            <div class="text2">
                {{ productInfo.averageLeadTime }}
            </div>
        </div>
        <div class="product-info-item" v-if="tags.length > 0">
            <div class="text1">{{ $t('te-dian-biao-qian') }}</div>
            <div class="tags">
                <el-tag v-for="(item, index) in tags" :key="index" effect="plain" size="mini">
                    {{ getName(item) }}
                </el-tag>
            </div>
        </div>

        <div class="like">
            <el-button type="text" :disabled="preview" :class="{ active: isCollection }" @click="collection">
                <div class="btn-content">
                    <span class="iconfont">{{ isCollection ? '&#xe8c3;' : '&#xe616;' }}</span>
                </div>
            </el-button>

            <el-popover placement="bottom" :disabled="preview" trigger="hover">
                <el-button type="text" style="margin-left: 30px;" @click.stop.prevent="" slot="reference">
                    <div class="btn-content">
                        <span class="iconfont">&#xe617;</span>
                    </div>
                </el-button>
                <share isBrand :info="productInfo" v-show="isChinese"></share>
                <share isBrand :info="productInfo" local="en" v-show="!isChinese"></share>
            </el-popover>
        </div>

        <div class="btn-box">
            <div class="btn-top">
                <el-button :disabled="preview" class="zixun" type="warning" @click="advisory(productInfo, 'product')">
                    <div class="btn-content"><span class="iconfont">&#xe615;</span>{{ $t('kuai-su-xun-jia') }}</div>
                </el-button>
            </div>

            <div class="btn-list">
                <el-button :disabled="preview" @click="addIntentionList(productInfo.id)">{{
                    $t('tian-jia-yi-xiang-dan')
                }}</el-button>
                <el-button :disabled="preview" @click="addContrast(productInfo.id)">{{
                    $t('tian-jia-dui-bi')
                }}</el-button>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import UserStoreOperation from '../../mixins/UserStoreOperation';
import Map from '../imt/Map.vue';
import Share from '../Share.vue';
export default {
    name: 'DetailTop',
    mixins: [UserStoreOperation],
    data() {
        return {
            collectionType: 'PRODUCT',
            showMap: false
        };
    },
    props: {
        productInfo: {
            type: Object,
            default: () => {
                return {};
            }
        },
        tags: {
            type: Array,
            default: () => {
                return [];
            }
        },
        preview: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapState(['userInfo']),
        config() {
            return {
                url: 'https://www.imttech.cn/index/productDetail?id=' + this.productInfo.id,
                title: this.getName(this.productInfo), // 标题，默认读取 document.title 或者 <meta name="title" content="share.js" />
                description: this.getName(this.productInfo, ['model', 'enModel']), // 描述, 默认读取head标签：<meta name="description" content="PHP弱类型的实现原理分析" />
                image: this.img, // 图片, 默认取网页中第一个img标签
                sites: ['qq', 'weibo', 'wechat', 'facebook', 'twitter'], // 启用的站点
                disabled: [], // 禁用的站点
                wechatQrcodeTitle: this.$t('wei-xin-sao-yi-sao-fen-xiang'), // 微信二维码提示文字
                wechatQrcodeHelper: `<p>${this.$t('wei-xin-li-dian-fa-xian-sao-yi-xia')}</p><p>${this.$t(
                    'er-wei-ma-bian-ke-jiang-ben-wen-fen-xiang-zhi-peng-you-quan'
                )}</p>`
            };
        },
        img() {
            return this.productInfo.img && this.productInfo.img.split(',').length > 0
                ? this.productInfo.img.split(',')[0]
                : '';
        }
    },
    watch: {
        productInfo() {
            this.$nextTick(() => {
                this.cId = this.productInfo.id;
            });
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.cId = this.productInfo.id;
        });
    },
    components: {
        Map,
        Share
    }
};
</script>
<style lang="scss" scoped>
@import '../../styles/variables';
.product-info {
    flex-grow: 1;
    padding: 80px 110px 112px;
    display: flex;
    flex-direction: column;

    .title {
        font-size: 28px;
        font-weight: bold;
        color: #292c33;
        line-height: 37px;
    }

    .sub {
        font-size: 18px;
        font-weight: bold;
        color: #878d99;
        line-height: 24px;
        padding: 10px 0;
        min-height: 30px;
    }

    .el-link {
        align-self: flex-start;
    }

    .product-info-item {
        display: flex;
        align-items: center;
        margin-top: 10px;

        .text1 {
            font-size: 12px;
            color: #bcc1cc;
            line-height: 22px;
            min-width: 72px;
            margin-right: 16px;
        }

        .text2 {
            font-size: 12px;
            color: #565b66;
            line-height: 22px;
        }

        .tags {
            display: flex;
            align-items: center;
            .el-tag + .el-tag {
                margin-left: 10px;
            }

            .el-tag {
                color: #565b66;
                border-color: #cfd5e1;
                border-radius: 0;
            }
        }
    }

    .like {
        padding: 15px 0 75px;
        .iconfont {
            font-size: 32px;
            color: #bcc1cc;
        }

        .el-button {
            &.active {
                .iconfont {
                    color: #ffa526;
                }
            }
            &:hover {
                .iconfont {
                    color: #ffa526;
                }
            }
        }

        .el-button + .el-button {
            margin-left: 30px;
        }

        &.previewLike {
            position: relative;
            &::after {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
            }
        }
    }

    .btn-box {
        width: 330px;
        .btn-top {
            .el-button {
                width: 100%;
            }
        }

        .btn-list {
            margin-top: 20px;
            .el-button {
                width: 158px;
                border-width: 0px;
                background-color: #f2f3f5;
                color: #0f264d;

                &:hover {
                    background-color: mix($--color-black, #f2f3f5, $--button-active-shade-percent);
                }
            }
            .el-button + .el-button {
                margin-left: 14px;
            }
        }
    }
}

.zixun {
    .btn-content {
        font-size: 14px;
        .iconfont {
            padding: 0 5px;
        }
    }
}

.close-Map {
    position: absolute;
    right: 0px;
    top: -42px;
    width: 71px;
    height: 32px;
    background: #00000043;

    font-size: 12px;
    color: #fdffff;
    line-height: 32px;
    text-align: center;
    cursor: pointer;

    &:hover {
        background: #00000065;
    }
}
</style>

<template>
    <div class="aside">
        <div class="top">{{ $t('xiang-guan-chan-pin') }}</div>
        <div v-for="item in list" :key="item.id" :class="{ asideItem: preview }">
            <Gride :info="item" :isNew="false" noLogo />
        </div>
    </div>
</template>
<script>
import Gride from '../../components/product/Gride';
export default {
    name: 'ProductRelated',
    data() {
        return {
            list: []
        };
    },
    props: {
        productId: {
            type: [Number, String],
            default: 0
        },
        preview: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        productId() {
            if (this.productId) {
                this.$http
                    .get('/product/related', {
                        id: this.productId
                    })
                    .then(res => {
                        this.list = res;
                    });
            }
        }
    },
    components: {
        Gride
    },
    mounted() {
        if (this.productId) {
            this.$http
                .get('/product/related', {
                    id: this.productId
                })
                .then(res => {
                    this.list = res;
                });
        }
        if (this.preview) {
            this.list = [
                {
                    img: 'http://oss.imttech.cn/micro/video.png',
                    chName: '**********',
                    model: '...........'
                },
                {
                    img: 'http://oss.imttech.cn/micro/video.png',
                    chName: '**********',
                    model: '...........'
                },
                {
                    img: 'http://oss.imttech.cn/micro/video.png',
                    chName: '**********',
                    model: '...........'
                },
                {
                    img: 'http://oss.imttech.cn/micro/video.png',
                    chName: '**********',
                    model: '...........'
                },
                {
                    img: 'http://oss.imttech.cn/micro/video.png',
                    chName: '**********',
                    model: '...........'
                }
            ];
        }
    }
};
</script>
<style lang="scss" scoped>
.top {
    height: 42px;
    background: #0f264d;
    font-size: 14px;
    font-weight: bold;
    color: #ffffff;
    line-height: 42px;
    padding: 0 12px;
}

.aside {
    background-color: #f5f7fa;
}

.gride {
    margin-bottom: 10px;
    display: block;
}

.asideItem {
    position: relative;
    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: #00000000;
    }
}
</style>
